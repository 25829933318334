import React from "react"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/policy.css"

import PolicyBanner from "../images/agreements.svg"

const PolicyPage = () => (
  <Layout>
    <SEO title="Terms and condition us" />
    <section className="section-intro">
      <div className="section--content">
        <div className="home--left--section">
          <h1 className="home--banner-text">Policies and Agreement</h1>
          <h4 className="home--banner-text-small">
            Please be sure to understand our terms and policy These terms and
            conditions apply to your use of the MUVIT mobile application and
            other related online and offline platforms ("MUVIT"), as well as to
            all information, recommendations and/or Services (defined below)
            provided to you by means of your use of MUVIT.
          </h4>
        </div>
        <div className="home--right--section">
          <div
            className="home-delivery--illustration"
            style={{ height: "220px" }}
          >
            <img src={PolicyBanner} alt="" />
          </div>
        </div>
      </div>
    </section>
    <section className="our-policies">
      <h2 className="section--content--header center">Our Policies</h2>
      <div className="section--content flex-section">
        <p>
          This privacy policy explains how Muvit Online Limited (a limited
          liability company incorporated under the laws of The Federal Republic
          of Nigeria), its subsidiaries, affiliates and associated companies
          (“we”, “us” or “our”) collect, hold, process, use, share and protect
          personal data about you when you use our Muvit application, website or
          other platform ("Muvit"). This privacy policy covers both the “online”
          (e.g., web and mobile services) and “offline” (e.g., collection of
          data through telephone or in person) activities owned, operated,
          provided and/or made available by us. This privacy policy specifically
          addresses our obligations in respect of personal data privacy under
          the Personal Data Laws of The Federal Republic of Nigeria.
          <hr />
          <hr />
          Where practicable, we undertake to also apply the principles and the
          processes to our operations globally. If our operations are subject to
          privacy legislation other than that of The Federal Republic of
          Nigeria, then this privacy policy will apply so far as practicable and
          consistent with such legislation.
          <hr />
          <hr />
          <hr />
          Please review this privacy policy carefully so that you understand our
          privacy policies and practices. By using Muvit or by otherwise giving
          us your information (INCLUDING PERSONAL DATA), you acknowledge and
          agree to the terms of this privacy policy. We will request all persons
          using Muvit to voluntarily provide us with the personal data (as set
          out in section 1 below) for the purposes as set out in section 2
          below. Do not use Muvit or give us any of your information if you do
          not agree to the terms of this privacy policy. However, we will not be
          able to provide Services (such term as defined in our Terms and
          Conditions) to you if you do not provide such information about you to
          us.
          <hr />
          <hr />
          We may update or change this privacy policy from time to time to
          reflect our latest privacy policies and practices. Any changes will be
          effective immediately upon posting of the revised privacy policy. If
          we make material changes, we will notify you by means of a notice on
          Muvit. We recommend that you review our privacy policy regularly for
          information about our latest privacy policies and practices.
          <hr />
          <hr />
          Since we use the Google Maps Application Program Interface (API),
          Google’s privacy policy, as may be amended by Google from time to
          time, is incorporated by reference:
          <a
            className="policies-ref"
            href="https://policies.google.com/privacy"
          >
            https://policies.google.com/privacy
          </a>
        </p>

        <div className="policy-list">
          <div className="policy--item">
            <h3 className="policy--item--header">
              1. What information do we collect, hold, process and/or use about
              you?
            </h3>
            <p>
              We may collect, hold, process and/or use the following data about
              you:
              <hr />
              <hr />
              – your name, your email, your BVN number, your telephone number
              and your location data (if you do not want your devices to provide
              us with location-tracking information, you can disable the GPS or
              other location-tracking functions on your device. However, if such
              functions are disabled, you may not be able to use some of the
              Services.);
              <hr />
              – Details about the device you use to access Muvit, which may
              include your IP address or other unique device identifiers;
              <hr />
              – Cookies information as set out in section 7 below;
              <hr />
              – Any other information provided by you pursuant to your use of
              Muvit; and
              <hr />
              – If you contact us, a record of that correspondence.
              <hr />
              If you are a driver/partner/user of Muvit, we may also collect,
              hold, process and/or use the following data about you:
              <hr />– Your contact details, other registration or profile
              information and reporting information about your use of Muvit; –
              Details required in order to collect and pay to you any fees as
              relevant; – Ratings information (where other users of Muvit are
              able to rate you as a Muvit driver/partner); and – Your
              driver/partner performance, operational and financial details.
            </p>

            <p>
              <hr />
              <hr />
              You understand and acknowledge that this privacy policy does not
              apply to any unsolicited information you provide to us through
              Muvit or through any other means. All unsolicited information
              shall be deemed to be non-confidential and we shall be free to
              reproduce, use, disclose and distribute such unsolicited
              information to others without limitation or attribution.
            </p>
          </div>
          <div className="policy--item">
            <h3 className="policy--item--header">
              2. What do we use your information for?
            </h3>
            <p>
              The purposes for our collection, holding, processing and/or use of
              information and/or data about you on Muvit include the following:
              <hr />
              <hr />
              – To verify your identity;
              <hr />
              – To provide the Services to you, including processing any
              bookings and payments;
              <hr />
              – To operate, protect, improve and optimize the Services and to
              improve and customize the experiences of you and other
              <hr />
              <hr />
              <h4> users of Muvit when using the same;</h4>
              <hr />
              <hr />
              – To notify you about changes to Muvit or respond to any
              communication and/or request from you;
              <hr />
              – To monitor the use of Muvit;
              <hr />
              – To investigate any complaints or potential breaches of the
              Services;
              <hr />
              – To send you marketing information about our products or services
              which maybe of your interest (including but not limited to,
              products and services of our partners, companies, subsidiaries,
              our business partners and our agents);
              <hr />
              – Where applicable and subject to compliance, to provide you with
              marketing and promotional materials for your enjoyment of benefits
              as users of Muvit;
              <hr />
              – only when you have provided the consents required under the law,
              to provide you with personal newsletters, marketing and
              promotional messages and other information as set out in section 4
              below;
              <hr />
              – To identify those of you who have enjoyed benefits as users of
              Muvit by receiving and using marketing and promotional materials;
              <hr />
              – To design and provide products and services to you in relation
              to the above purposes;
              <hr />
              – To compile aggregate statistics about you and to analyse Muvit
              and service usage;
              <hr />
              – where applicable and subject to compliance of the law, to match
              (as defined in the law) your personal data with other data
              collected for other purposes and from other sources including
              third parties in relation to the provision of goods and Services
              to you;
              <hr />
              – to facilitate our use of your personal data for purposes
              relating to the provision of Services offered by us and marketing
              services, special events and/or promotions of us and/or our
              clients;
              <hr />
              – To comply with our obligations under the applicable laws and
              regulations from time to time; and
              <hr />
              – to disclose to and/or assist relevant authorities where we
              suspect you have committed a criminal offence, where we suspect
              existence of fraud or when required by the relevant laws or court
              orders, or as requested by relevant law enforcement authorities.
              <hr />
              <hr />
              <hr />
              We will seek your (or seek from your respective relevant persons
              express consents to changes in how we use (including disclose)
              your personal data if requested by law, but otherwise use of the
              Services following such changes constitutes your acceptance of the
              revised statement then in effect.
            </p>
          </div>
        </div>
        <div className="policy--item">
          <h3 className="policy--item--header">
            3. Do we disclose any information to outside parties?
          </h3>
          <p>
            We generally only permit our duly authorized staff to access your
            personal information and data, and we will only release such
            personal information and data to third parties under the following
            circumstances:
            <hr />
            <hr />
            – where the information and/or data is disclosed and/or transferred
            to any third-party suppliers or external service providers who have
            been duly authorized by us to use such information and/or data and
            who will facilitate the Services on Muvit, under a duty of
            confidentiality;
            <hr />
            – Where the information and/or data is disclosed and/or transferred
            to our agents who have been duly authorized by us to use such
            information and/or data;
            <hr />
            – where the information and/or data is disclosed and/or transferred
            to any other parties in connection with a sale or proposed sale of
            all or part of our business or portfolio of products or other
            assets;
            <hr />
            – where the information and/or data is disclosed and/or transferred
            to us (including but not limited to, our affiliates, holding
            companies, subsidiaries, fellow subsidiaries, our business partners
            and our agents) in connection with marketing and/or cross marketing
            about the products or services which maybe of your interest;
            <hr />
            – only when you have provided the consents required under the Law,
            where the information and/or data is disclosed, provided and/or
            transferred to any Outside Parties (as defined below) in connection
            with direct marketing as set out in section 4 below;
            <hr />
            <hr />
            – To credit reference agencies, debt collection agencies, fraud
            detection and/or prevention agencies;
            <hr />
            – to any relevant public authorities or law enforcement agencies,
            including, for example, where we suspect you have committed a
            criminal offence, where we suspect existence of fraud or where
            required by applicable law, regulation or legal proceedings;
            <hr />
            – In order to enforce or apply the terms and conditions of your use
            of the Muvit or any other agreement to which you and we are a party,
            as applicable; and
            <hr />
            – Where we deem necessary, in order to maintain and improve the
            Services.
            <hr />
            <hr />
            You should take care when using any Muvit social networking features
            since the information you choose to make available can be seen by
            other users in the selected group. You understand and acknowledge
            that we cannot control the actions of other users of social
            networking features with whom you may choose to share your
            information (which may include your personal data). We do not
            monitor the use of such features in the normal course of business
            although we reserve the right to do so at our discretion. Therefore,
            you understand and acknowledge that we cannot and do not guarantee
            that your information (which may include your personal data) will
            not be reviewed by unauthorized persons.
          </p>
        </div>
        <div className="policy--item">
          <h3 className="policy--item--header">
            4. Do we use any information for direct marketing?
          </h3>
          <p>
            We may from time to time send you personal newsletters,
            promotional/marketing materials and messages based on the personal
            information and data that you have provided to us only if you have
            given us the consent (including, an indication of no objection) as
            required under the Law to use your personal data for such purpose.
            We may use your information and data in direct marketing and we
            require your consent (including, an indication of no objection) as
            required under the Law to use your personal data for that purpose
            <hr />
            <hr />
            The following classes of services, products and subjects may be
            marketed:
            <hr />
            <hr />
            – Transport, logistics and automotive related products and services;
            <hr />
            – Special events hosted by us for you and other users of Muvit,
            including but not limited to competitions and social gatherings;
            <hr />
            – Reward, loyalty or privileges programmes in relation to the
            Services and related products;
            <hr />
            – Special offers in relation to the Services and related products,
            including but not limited to coupons, discounts, purchase offers and
            promotional campaigns;
            <hr />
            – products and services offered by us (including our affiliates,
            holding companies, subsidiaries, fellow subsidiaries, our business
            partners and our agents) which maybe of your interest;
            <hr />
            – Products and services offered by us and our advertisers (the names
            of which can be found in the relevant advertisements and/or
            promotional or marketing materials for the relevant products and
            services, as the case may be); and
            <hr />
            – Donations and contributions for charitable and/or non-profit
            making purposes.
            <hr />
            <hr />
            The above products, services and subjects may be provided or
            solicited by us, third-party service providers providing the above
            services, products and subjects and charitable and/or non-profit
            making organizations (“Outside Parties”). We may provide your
            personal information and/or data to Outside Parties for use by them
            in marketing their above services, products and subjects and we
            require your consent (including, an indication of no objection) as
            required under the law to use your personal data for that purpose.
          </p>
        </div>
        <div className="policy--item">
          <h3 className="policy--item--header">
            5. Can I access and correct my personal data?
          </h3>
          <p>
            In accordance with the terms of the Law and subject to the
            exemptions specified in the Law, you (or your respective relevant
            persons as defined under the Law on your behalf) have the right to:
            <hr />
            <hr />
            – check whether we hold personal data about you and, if we hold
            personal data about you, to receive copies of such data; and <hr />
            – require us to correct any personal data relating to you which is
            inaccurate. <hr />
            <hr />
            Requests for access to personal data, correction of personal data
            and information relating to the kind of data held, may be made by
            contacting our Data Privacy Officer by email at info@muvit.com.ng.
            Any such request should also clearly state the details of personal
            data in respect of which the request is being made and we may
            request you to prove your identity in relation to your request to
            access and/or correct your personal data.
          </p>
        </div>
        <div className="policy--item">
          <h3 className="policy--item--header">
            6. How do we protect your personal data?
          </h3>
          <p>
            We have adequate measures to protect your personal information from
            unauthorized access, accidental loss or destruction. Where we have
            given you (or you have chosen) a password which enables you to
            access certain parts of the Services, you are responsible for
            keeping this password confidential. We ask you not to share your
            password with anyone.
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            Unfortunately, the transmission of information via the Internet is
            not completely secure. While we will do our best to protect your
            information and data, we cannot guarantee the security of data
            transmitted to the Services; any transmission is at your own risk.
            Once we have received your information and data, we will use strict
            procedures and security features to try to prevent unauthorised
            access.
          </p>
        </div>
        <div className="policy--item">
          <h3 className="policy--item--header">7. Do we use cookies?</h3>
          <p>
            We use Google Analytics on our website to help analyse how users use
            the site. Google Analytics is a web analysis service provided by
            Google LLC. Google utilizes the data collected to track and examine
            the use of our website, to prepare reports on its activities and
            share them with other Google services. Google may use the data
            collected to contextualize and personalize the advertisements of its
            own advertising network. Google Analytics features implemented on
            this site include Display Advertising (Demographics and Interest
            Reporting). Google’s ability to use and share information collected
            by Google Analytics regarding your visits to this site is restricted
            by the Google Analytics Terms of Service.
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            We do use certain cookies (such as Google Analytics’ cookies) and
            other similar technologies as part of our Services. The cookies used
            are for the purposes of enabling you to use the Services (for
            example, to enable you to complete a registration or access
            information) and for us to understand and save your preferences for
            future visits, analyse usage patterns, keep track of advertisements
            and compile aggregate data about site traffic and site interaction
            so that we can offer better site experiences and tools for Muvit in
            the future. We may contract with third-party service providers to
            assist us in better understanding our site visitors in accordance
            with this privacy policy. These service providers are not permitted
            to use the information collected on our behalf except to help us
            conduct and improve our business.
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            You can choose to disable cookies (through changing your browser or
            device settings) although you should be aware that this may prevent
            you from being able to use all parts of the Services.
          </p>
        </div>
        <div className="policy--item">
          <h3 className="policy--item--header">
            8. Retention of personal data
          </h3>
          <p>
            Once we have obtained your personal information and/or data, it will
            be maintained securely in our system. Subject to legal requirements
            and our internal policy, your personal information and/or data may
            be retained by us after deactivation of the relevant Services where
            such personal data is still required for the purpose for which the
            personal data was used.
          </p>
        </div>
        <div className="policy--item">
          <h3 className="policy--item--header">
            9. Links to third-party websites
          </h3>
          <p>
            Subject to this privacy policy, at our discretion, we may include or
            offer third-party products or services on Muvit. You understand and
            acknowledge that the fact that the we may include or offer
            third-party products or services on Muvit does not mean that we
            endorses or authorizes the collection of personal data from you to
            such third parties, nor does it constitute a representation of any
            affiliation between us and such third parties, You understand and
            acknowledge that, once you click on a link to third-party websites,
            applications or advertisements, you will access third-party
            websites, applications and advertisements which may collect
            information from you. You understand and acknowledge that such
            third-party websites applications and advertisements follow separate
            and independent privacy policies regarding the collection, holding,
            processing, use and/or disclosure of the personal data you submit to
            them. Hence, you understand and acknowledge that we have no
            responsibility or liability for the content and activities of these
            linked websites, applications and advertisements (including any
            collection, holding, processing, use and/or disclosure of your
            personal data by such third parties). Nonetheless, we seek to
            protect the integrity of Muvit and welcome any feedback about these
            sites.
          </p>
        </div>
      </div>
    </section>
  </Layout>
)
export default PolicyPage
